const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import(/* webpackChunkName: "WebVitals" */ "web-vitals")
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
      })
      .catch(() =>
        // eslint-disable-next-line no-console
        console.warn("Error initialising webVitals")
      )
  }
}

export default reportWebVitals
