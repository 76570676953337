import { CONFIG } from "@meili/config"
import localeList from "@meili/lists/dist/json/localeList.json"
import { getListItemByValue } from "@meili/lists/dist/utils"

/**
 * Get language from locale.
 * For most locales we just split and take the first part but there are some exceptions.
 * For example, if locale is zh-Hans we need to use zh-Hans as language.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. en) if locale is not passed or not found
 */
export function getLanguage(locale?: string): string {
  const definedLocale = getListItemByValue(localeList, locale)
  const [defaultLanguage] = CONFIG.REACT_APP_DEFAULT_LOCALE.split("-")

  return definedLocale?.language ?? locale?.split("-")?.[0] ?? defaultLanguage
}

/**
 * Get country from locale.
 * For most locales we just split and take the second part but there are some exceptions.
 * For example, if locale is zh-Hans we need to use CN as country.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. GB) if locale is not passed or not found
 */
export function getCountry(locale?: string): string {
  const definedLocale = getListItemByValue(localeList, locale)
  const [, defaultCountry] = CONFIG.REACT_APP_DEFAULT_LOCALE.split("-")

  return definedLocale?.country ?? locale?.split("-")?.[1] ?? defaultCountry
}

/**
 * Get date locale from locale.
 * For most locales they are the same but there are some exceptions.
 * For example, if locale is no-NO we need to use nb as date locale.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. en-GB) if locale is not passed or not found
 */
export function getDateLocale(locale?: string): string {
  const definedLocale = getListItemByValue(localeList, locale)

  return definedLocale?.dateLocale ?? locale ?? CONFIG.REACT_APP_DEFAULT_LOCALE
}

/**
 * Get fallback locales.
 * For most locales i18next can figure out the fallbacks but there are some exceptions.
 * For example, if locale is zh-CN we need to fallback to zh-Hans.
 */
export function getFallbackLocales(): Record<string, string[]> {
  return localeList.reduce(
    (locales, { value, fallback }) =>
      fallback ? { ...locales, [value]: fallback } : locales,
    {}
  )
}

/**
 * Check if language is currently supported by the app.
 */
export function isLanguageSupported(locale?: string) {
  if (!locale) return false

  const supportedLanguages =
    typeof CONFIG.REACT_APP_SUPPORTED_LANGUAGES === "string"
      ? CONFIG.REACT_APP_SUPPORTED_LANGUAGES.split(",")
      : CONFIG.REACT_APP_SUPPORTED_LANGUAGES

  return supportedLanguages.includes(locale)
}
