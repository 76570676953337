import { ConfigAPIEndpoint } from "./endpoints"
import { getHeadersForAPI, checkResponse } from "./utils"

// Actions
export const doConfigAPI = (config) =>
  fetch(ConfigAPIEndpoint, {
    method: "GET",
    headers: getHeadersForAPI(config)
  }).then((response) => checkResponse(response))

export default {
  doConfigAPI
}
