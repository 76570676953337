/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { createContext, useContext, useEffect, useRef } from "react"
import type { NavigateFunction } from "react-router-dom"

import { CONFIG } from "@meili/config"

const importDatadog = () => import("monitoring/datadog")
const importSentry = () => import("monitoring/sentry")

export interface Warning {
  code: string
  description?: string
  isCritical?: boolean
}

export class UIMonitor {
  // eslint-disable-next-line no-useless-constructor
  constructor(private providers: UIMonitor[] = []) {}

  static init(tools: ("sentry" | "datadog")[]) {
    // An array of partial domain names where the use of Datadog is restricted.
    // Some partners don't want to allow Datadog so it should not be initialized for their domains.
    const RESTRICTED_PARTNER_DOMAINS =
      CONFIG.REACT_APP_RESTRICTED_PARTNER_DOMAINS?.length > 0
        ? CONFIG.REACT_APP_RESTRICTED_PARTNER_DOMAINS.split(",")
        : []
    const providers: UIMonitor[] = []
    if (tools.includes("sentry")) {
      providers.push(new SentryMonitor())
    }
    if (
      tools.includes("datadog") &&
      // Check if the current domain (window.location.origin) contains any of the partial domain names
      // listed in the RESTRICTED_PARTNER_DOMAINS array. If none are found, proceed to initialize Datadog.
      !RESTRICTED_PARTNER_DOMAINS.some((domain) =>
        window.location.origin.includes(domain)
      )
    ) {
      providers.push(new DatadogMonitor())
    }

    return new UIMonitor(providers)
  }

  captureException = (error: Error) => {
    this.providers.forEach((provider) => {
      provider.captureException(error)
    })
  }

  setUser = (user: { id: string }) => {
    this.providers.forEach((provider) => {
      provider.setUser(user)
    })
  }

  setTag = (key: string, value: string) => {
    this.providers.forEach((provider) => {
      provider.setTag(key, value)
    })
  }

  useProfiler = (name: string): void =>
    this.providers.forEach((provider) => {
      provider.useProfiler(name)
    })

  startView = (name: string): void =>
    this.providers.forEach((provider) => {
      provider.startView(name)
    })
}

class SentryMonitor extends UIMonitor {
  private provider?: any

  constructor() {
    super()

    importSentry().then(({ default: initaliseSentry }) => {
      this.provider = initaliseSentry(0.1, 0.005)
    })
  }

  captureException = (error: Error) => {
    this.provider?.captureException(error)
  }

  setUser = (user: { id: string }) => {
    this.provider?.setUser(user)
  }

  setTag = (key: string, value: string) => {
    this.provider?.setTag(key, value)
  }

  useProfiler = (name: string): void => {
    this.provider?.useProfiler(name)
  }

  startView = (): void => {
    // Not implemented
  }
}

class DatadogMonitor extends UIMonitor {
  private provider?: any

  constructor() {
    super()

    importDatadog().then(({ default: initialiseDatadog }) => {
      this.provider = initialiseDatadog()
    })
  }

  captureException = (error: Error) => {
    this.provider?.addError(error)
  }

  setUser = (user: { id: string }) => {
    this.provider?.setUser(user)
  }

  setTag = (key: string, value: string) => {
    this.provider?.setGlobalContextProperty(key, value)
  }

  useProfiler = (name: string): void => {
    const nameRef = useRef(name)
    const startRef = useRef(window.performance.now())
    useEffect(() => {
      this.provider?.addTiming(`mount_${nameRef.current}`, startRef.current)
    }, [])
  }

  startView = (name: string): void => {
    this.provider?.startView(name)
  }
}

export const UIMonitorContext = createContext<{
  monitor: UIMonitor | null
  warning: Warning | null
  setWarning: ((param: Warning) => NavigateFunction) | null
}>({ monitor: null, warning: null, setWarning: null })

export function useUIMonitor() {
  const context = useContext(UIMonitorContext)
  if (!context) {
    throw new Error(
      "useUIMonitor must be used within an UIMonitorContext.Provider."
    )
  }

  return context
}

export function useProfiler(name: string) {
  useUIMonitor()?.monitor?.useProfiler(name)
}
