import { useEffect } from "react"

import { pushToTagging } from "analytics/utils"

import { useMeiliParams } from "integration/MeiliParamsContext"

import { sessionStorageLoadState } from "localStorage/utils"

interface PageTitleOptions {
  title: string
  setAppDataTitle?: boolean
  isReady?: boolean
}

/**
 * A custom React hook for setting and tracking page titles for analytics purposes.
 *
 * @param {PageTitleOptions} options - An object containing the options for the hook.
 * @param {string} options.title - The title to set for the page for analytics.
 * @param {boolean} [options.setAppDataTitle=true] - Set to false to disable setting data-title on #mli-app.
 * @param {boolean} [options.isReady=true] - Is ready to push pageview.times to analytics
 *
 * @returns {void}
 */
const usePageTitle = (options: PageTitleOptions) => {
  const { title, setAppDataTitle = true, isReady = true } = options
  const { ptid } = useMeiliParams()

  useEffect(() => {
    const funnelId = sessionStorageLoadState("meili-tagging")?.funnelId

    if (title && setAppDataTitle) {
      document.getElementById("mli-app")?.setAttribute("data-title", title)
    }

    if (isReady) pushToTagging(ptid, `${title}.times`, funnelId)
  }, [isReady, ptid, setAppDataTitle, title])
}

export default usePageTitle
