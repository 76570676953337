import clsx from "clsx"

export const getPartnerStyles = async (
  ptid,
  url,
  style = "partner",
  darkMode = false
) => {
  if (ptid) {
    const [stylePartnerID, styleTouchPointId] = ptid.split(".")
    const basePartnerUrl = `${url}/partner_content/${stylePartnerID}`
    const baseTouchPointUrl = `${basePartnerUrl}/${styleTouchPointId}/style`

    const urls = {
      partner: {
        default: `${basePartnerUrl}/partner.css`,
        darkMode: `${basePartnerUrl}/partner-dark.css`
      },
      touchpoint: {
        default: `${baseTouchPointUrl}/touchpoint.css`,
        darkMode: `${baseTouchPointUrl}/touchpoint-dark.css`
      }
    }

    const styleUrls = []

    const message =
      style === "partner"
        ? `Custom stylesheet not found for partner - ${stylePartnerID}`
        : `Custom stylesheet not found for partner touchpoint - ${stylePartnerID}.${styleTouchPointId}`

    if (style in urls) {
      styleUrls.push(urls[style].default)
      if (darkMode) {
        styleUrls.push(urls[style].darkMode)
      }
    }

    try {
      const styleSheets = await Promise.all(
        styleUrls.map(async (styleUrl) => {
          const styleSheet = await fetch(styleUrl, {
            headers: {
              "Cache-Control": "no-cache"
            }
          })

          if (styleSheet?.status !== 200) {
            // eslint-disable-next-line no-console
            console.warn(message)
            return ""
          }

          return styleSheet.text()
        })
      )

      const combinedStyles = styleSheets.reduce(
        (acc, styleText) => acc + styleText,
        ""
      )

      return combinedStyles
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(message)
    }
  }
  return ""
}

export const mliClassNames = (...classes) => {
  const normalisedClasses = clsx(...classes)

  const splitClasses = normalisedClasses.split(" ")

  return splitClasses
    .map((splitClass) =>
      splitClass.startsWith("mli-") ? splitClass : `mli-${splitClass}`
    )
    .join(" ")
}

export default { getPartnerStyles }
