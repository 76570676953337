import { useEffect, useState } from "react"

import { CONFIG } from "@meili/config"
import { statusEnums } from "@meili/services/api/utils"

import { getPartnerStyles } from "style/utils"

import { useMeiliParams } from "integration/MeiliParamsContext"

export const usePartnerConfig = () => {
  const { ptid, darkmode } = useMeiliParams()
  const [partnerConfig, setPartnerConfig] = useState({})
  const [styleContent, setStyleContent] = useState("")
  const [status, setStatus] = useState(statusEnums.INITIAL)

  useEffect(() => {
    if (ptid) {
      setStatus(statusEnums.PENDING)
      const [partnerId, touchpointId] = ptid.split(".")
      fetch(
        `${CONFIG.REACT_APP_CONTENT_API}/partner_content/${partnerId}/${touchpointId}/content/config.json`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            "Cache-Control": "no-cache"
          }
        }
      )
        .then((response) => response.json())
        .then((config) => {
          setPartnerConfig(config)
          return config
        })
        .catch(() => {
          setStatus(statusEnums.REJECTED)
          // eslint-disable-next-line no-console
          console.warn(
            `Partner config for PTID ${ptid} does not exist, no additional partner config added, default config will be used`
          )
        })
        .then(async (config) => {
          const fullConfig = {
            ...CONFIG,
            ...config
          }
          const url = fullConfig.REACT_APP_CONTENT_API
          const style = fullConfig.REACT_APP_CUSTOM_STYLES
          const darkmodeEnabled = darkmode && fullConfig.REACT_DARKMODE_ENABLED

          const styles = await getPartnerStyles(
            ptid,
            url,
            style,
            darkmodeEnabled
          )

          return setStyleContent(styles)
        })
        .finally(() => {
          setStatus(statusEnums.FULFILLED)
        })
    }
  }, [ptid, darkmode])

  return {
    status,
    styleContent,
    ...CONFIG,
    ...partnerConfig
  }
}

export default usePartnerConfig
