import PropTypes from "prop-types"

const totalCost = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  symbol: PropTypes.string
}

const location = {
  type: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  parentLocation: PropTypes.string,
  countryCode: PropTypes.string,
  phone: PropTypes.string,
  openingHours: PropTypes.arrayOf(PropTypes.string),
  address: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string
}

const driver = {
  addressLine1: PropTypes.string,
  city: PropTypes.string,
  countryCode: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNo: PropTypes.string,
  postCode: PropTypes.string,
  state: PropTypes.string
}

const fieldValue = {
  residency: PropTypes.string,
  driverAge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const informational = {
  code: PropTypes.string,
  text: PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string
  })
}

const propTypes = {
  bundle: {
    description: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    bundlePrice: PropTypes.shape({
      cost: PropTypes.shape(totalCost)
    }),
    bundleExclusives: PropTypes.arrayOf(PropTypes.shape(informational)),
    totalCost: PropTypes.shape(totalCost)
  },
  button: PropTypes.oneOf(["submit", "button", "reset"]),
  costs: {
    ids: PropTypes.arrayOf(PropTypes.string),
    entities: PropTypes.arrayOf(PropTypes.object)
  },
  criteria: {
    dropoffDate: PropTypes.string,
    dropoffTime: PropTypes.string,
    dropoffDateTime: PropTypes.string,
    dropoffLocation: PropTypes.shape(location),
    pickupDate: PropTypes.string,
    pickupTime: PropTypes.string,
    pickupDateTime: PropTypes.string,
    pickupLocation: PropTypes.shape(location),
    differentPickUpDropOff: PropTypes.bool,
    driverAge: fieldValue.driverAge,
    residency: fieldValue.residency
  },
  deeplink: {
    search: PropTypes.string
  },
  driver: PropTypes.shape(driver),
  extra: {
    code: PropTypes.string,
    reference: PropTypes.string,
    name: PropTypes.string,
    maxQuantity: PropTypes.number,
    description: PropTypes.string
  },
  filter: {
    name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      ])
    ),
    selected: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      ])
    ),
    title: PropTypes.string
  },
  form: {
    setFieldValue: PropTypes.func
  },
  field: {
    value: PropTypes.shape(fieldValue)
  },
  importantInfo: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string
    })
  ),
  locale: PropTypes.shape({
    country: PropTypes.string,
    label: PropTypes.string,
    language: PropTypes.string,
    value: PropTypes.string
  }),
  location,
  marker: {
    latitude: PropTypes.number,
    longitude: PropTypes.number
  },
  price: {
    discount: PropTypes.shape({
      originalPrice: PropTypes.shape(totalCost),
      percentageDiscount: PropTypes.number
    }),
    cost: PropTypes.shape(totalCost),
    burn: PropTypes.shape({
      points: PropTypes.number
    }),
  },
  referredTerms: PropTypes.shape({
    content: PropTypes.string,
    url: PropTypes.string
  }),
  rentalSummary: {
    amount: PropTypes.shape(totalCost),
    prepaidAmound: PropTypes.shape(totalCost),
    fees: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        amount: PropTypes.shape(totalCost)
      })
    ),
    taxes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        amount: PropTypes.shape(totalCost)
      })
    )
  },
  result: {
    priceId: PropTypes.string,
    costsId: PropTypes.string,
    vehicleId: PropTypes.string,
    id: PropTypes.string
  },
  selectedExtras: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    })
  ),
  summary: {
    dropoffDate: PropTypes.string,
    dropoffTime: PropTypes.string,
    dropoffDateTime: PropTypes.string,
    dropoffLocation: PropTypes.shape(location),
    pickupDate: PropTypes.string,
    pickupTime: PropTypes.string,
    pickupDateTime: PropTypes.string,
    pickupLocation: PropTypes.shape(location)
  },
  totalCost,
  vehicle: {
    baggageQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    doorCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    duration: PropTypes.number,
    freeInclusions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      })
    ),
    merchandisingFeatures: PropTypes.arrayOf(PropTypes.shape(informational)),
    fuelPolicy: PropTypes.shape(informational),
    fuelType: PropTypes.string,
    locationInfo: PropTypes.shape(informational),
    id: PropTypes.string,
    isElectric: PropTypes.bool,
    mileage: PropTypes.string,
    mileageText: PropTypes.string,
    name: PropTypes.string,
    orSimilar: PropTypes.bool,
    passengerQuantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    pictureURL: PropTypes.string,
    transmissionType: PropTypes.string,
    vehicleSize: PropTypes.string,
    vehicleGroup: PropTypes.string,
    vendorName: PropTypes.string,
    vendorImage: PropTypes.string,
    pickupLocation: PropTypes.shape({
      distanceFromSearchLocation: PropTypes.number
    })
  },
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  children: PropTypes.node,
  appSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  ...PropTypes
}

export default propTypes
