import { StrictMode, useMemo, useState } from "react"
import { createRoot } from "react-dom/client"
import { I18nextProvider } from "react-i18next"

import { CONFIG } from "@meili/config"

import ErrorBoundary from "monitoring/ErrorBoundary"
import { UIMonitor, UIMonitorContext } from "monitoring/UIMonitor"

import lazyLoad from "utils/lazyLoad"

import reportWebVitals from "./reportWebVitals"
import root from "./root"

import { AriaLiveProvider } from "./integration/AriaLiveContext"
import { MeiliParamsProvider } from "./integration/MeiliParamsContext"
import { PartnerContentProvider } from "./integration/PartnerContentContext"

// Import the internationalisation and language files
import i18nInstance from "./localisation"

const tools = (CONFIG.REACT_APP_UI_MONITORS ?? "").split(",")
const monitor = UIMonitor.init(tools)

// Make the apps available to load if requested to render.
const Connect = lazyLoad(() =>
  import(/* webpackChunkName: "Connect" */ "./apps/Connect")
)
const Direct = lazyLoad(() =>
  import(/* webpackChunkName: "Direct" */ "./apps/Direct")
)
const Search = lazyLoad(() =>
  import(/* webpackChunkName: "Search" */ "./apps/Search")
)
const Booking = lazyLoad(() =>
  import(/* webpackChunkName: "Booking" */ "./apps/Booking")
)

const container = createRoot(root)

const ProdMode =
  process.env.NODE_ENV === "production"
    ? StrictMode
    : ({ children }) => children

function App() {
  const [warning, setWarning] = useState(null)
  const memoizedValue = useMemo(
    () => ({ monitor, warning, setWarning }),
    [warning, setWarning]
  )

  return (
    <UIMonitorContext.Provider value={memoizedValue}>
      <ErrorBoundary displayError={false} chunkError>
        <I18nextProvider i18n={i18nInstance}>
          <ProdMode>
            <MeiliParamsProvider>
              <PartnerContentProvider>
                <AriaLiveProvider>
                  {CONFIG.REACT_APP_TO_USE === "Direct" && <Direct />}
                  {CONFIG.REACT_APP_TO_USE === "Search" && <Search />}
                  {CONFIG.REACT_APP_TO_USE === "Connect" && <Connect />}
                  {CONFIG.REACT_APP_TO_USE === "Booking" && <Booking />}
                </AriaLiveProvider>
              </PartnerContentProvider>
            </MeiliParamsProvider>
          </ProdMode>
        </I18nextProvider>
      </ErrorBoundary>
    </UIMonitorContext.Provider>
  )
}

const renderApp = () => container.render(<App />)

if (CONFIG.REACT_APP_MOCKS) {
  import(/* webpackChunkName: "MSW" */ "@meili/services/msw/worker")
    .then(({ worker }) => {
      worker
        ?.start({
          onUnhandledRequest: "bypass",
          serviceWorker: {
            url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`
          }
        })
        .then(() => renderApp())
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn(e)
        })
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.warn(e)
    })
} else {
  renderApp()
}

reportWebVitals()
