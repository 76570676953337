import { makeDateTimeForAPI, getHeadersForAPI, checkResponse } from "./utils"
import { SearchAPIEndpoint } from "./endpoints"

function getAvailParams(criteria, currencyCode) {
  // Construct a datetime for the API if there is a date present
  const pickupDateTime = criteria.pickupDate
    ? makeDateTimeForAPI(criteria.pickupDate, criteria.pickupTime)
    : undefined
  const dropoffDateTime = criteria.dropoffDate
    ? makeDateTimeForAPI(criteria.dropoffDate, criteria.dropoffTime)
    : undefined

  return {
    pickupLocation:
      criteria.pickupLocation?.searchCode ??
      // we need to keep iataCode for backwards compatibility
      criteria.pickupLocation?.iataCode ??
      criteria.pickupLocation?.id,
    dropoffLocation:
      criteria.dropoffLocation?.searchCode ??
      // we need to keep iataCode for backwards compatibility
      criteria.dropoffLocation?.iataCode ??
      criteria.dropoffLocation?.id,
    ...(pickupDateTime && { pickupDateTime }),
    ...(dropoffDateTime && { dropoffDateTime }),
    driverAge: criteria.driverAge,
    residency: criteria.residency ?? criteria.countryCode,
    currencyCode
  }
}

// MPD-1961 We need to keep these legacy params (eg. "AFA", "AFC".) for backwards compatibility
const legacyParamsMap = {
  AFA: "airlineFareAmount",
  AFC: "airlineFareCurrency",
  ANC: "ancillaryActivity",
  ARR: "arrivalAirport",
  CHL: "child",
  CPC: "customerPartnerCode",
  DEP: "departureAirport",
  FTF: "fareTypeAndFlex",
  FTN: "flightNumber",
  INF: "infant",
  PAX: "numberOfPassengers",
  PNR: "passengerNameRecord",
  RET: "partnerCustomerID",
  ALA: "partnerLoyaltyAccount",
  ALT: "partnerLoyaltyAccountTier",
  SLA: "supplierLoyaltyAccounts",
  SNR: "superPassengerNameRecord",
  TEE: "teenager",
  TRT: "tripType",
  airlineLoyaltyAccount: "partnerLoyaltyAccount",
  airlineLoyaltyAccountTier: "partnerLoyaltyAccountTier",
  companyCode: "corporateCode"
}
const allowedPartnerParams = [
  "airlineFareAmount",
  "airlineFareCurrency",
  "ancillaryActivity",
  "arrivalAirport",
  "child",
  "customerPartnerCode",
  "departureAirport",
  "fareTypeAndFlex",
  "flightNumber",
  "infant",
  "numberOfPassengers",
  "passengerNameRecord",
  "partnerCustomerID",
  "partnerLoyaltyAccount",
  "partnerLoyaltyAccountTier",
  "supplierLoyaltyAccounts",
  "superPassengerNameRecord",
  "teenager",
  "tripType",
  "additionalData",
  "agentId",
  "iataNumber",
  "corporateCode"
]

function getPartnerParams(criteria) {
  const params = Object.entries(criteria)
    // map legacy keys to current keys
    .map(([key, value]) => [legacyParamsMap[key] ?? key, value])
    // filter only allowed keys
    .filter(([key]) => allowedPartnerParams.includes(key))
    // filter empty values
    .filter(([, v]) => v != null && v !== "")
  return Object.fromEntries(params)
}

export const createAvailAndPartnerParams = (criteria, currencyCode) => {
  return {
    // Required request parameters
    availParams: getAvailParams(criteria, currencyCode),
    // Optional request parameters
    partnerParams: getPartnerParams(criteria)
  }
}

// Actions
export const doSearchAPI = (config, criteria) => {
  const { currencyCode } = config

  const body = createAvailAndPartnerParams(criteria, currencyCode)

  return fetch(SearchAPIEndpoint, {
    method: "POST",
    headers: getHeadersForAPI(config),
    body: JSON.stringify(body)
  }).then((response) => checkResponse(response))
}

export default {
  createAvailAndPartnerParams,
  doSearchAPI
}
