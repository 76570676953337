import { useEffect, useState } from "react"

export type ListenerType = (mutations: MutationRecord) => void

let subscribers: Array<{ target: HTMLElement | null; listener: ListenerType }> =
  []

const mutationObserver = new MutationObserver((mutations) =>
  mutations.forEach((mutation: MutationRecord) => {
    const match = subscribers.find(
      (subscriber) => subscriber.target === mutation.target
    )

    if (match) {
      match.listener(mutation)
    }
  })
)

function upsertListener(target: HTMLElement | null, listener: ListenerType) {
  if (!target) {
    return
  }
  const match = subscribers.find((subscriber) => subscriber.target === target)
  if (match) {
    match.listener = listener
  } else {
    subscribers.push({ target, listener })
  }
}

/**
 * If the element being observed is removed from the DOM, and then subsequently
 * released by the browser's garbage collection mechanism, the MutationObserver
 * will stop observing the removed element. However, the MutationObserver itself
 * can continue to exist to observe other existing elements.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/disconnect#usage_notes}
 *
 * @param target
 * @returns
 */
function detach(target: HTMLElement | null) {
  if (!target) {
    return
  }
  subscribers = subscribers.filter((subscriber) => subscriber.target !== target)
}

const DEFAULT_OPTIONS = {
  attributes: true,
  characterData: true
}

const useMutationObserver = (
  listener: ListenerType,
  options = DEFAULT_OPTIONS
) => {
  const [ref, setRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    upsertListener(ref, listener)
  }, [ref, listener])

  useEffect(() => {
    if (ref) {
      mutationObserver.observe(ref, options)
    }

    return () => {
      detach(ref)
    }
  }, [ref, options])

  return { ref, setRef }
}

export default useMutationObserver
