import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend, { type HttpBackendOptions } from "i18next-http-backend"

import { CONFIG } from "@meili/config"

import { getFallbackLocales, isLanguageSupported } from "./utils"

export const backend: HttpBackendOptions = {
  loadPath: ([locale]) =>
    isLanguageSupported(locale)
      ? // Language code is _v2 as we switch over to the new translations process, so we have the old file as a backup.
        `${CONFIG.REACT_APP_LANGUAGE_API}/${locale}_v2.json`
      : "",
  crossDomain: true
}

const i18nInstance = i18n.use(initReactI18next)

i18nInstance
  .use(Backend)
  // Pass i18n to react-i18next for use
  .use(initReactI18next)
  // Initialise i18next
  .init({
    debug: false,
    fallbackLng: {
      default: [CONFIG.REACT_APP_DEFAULT_LANGUAGE],
      ...getFallbackLocales()
    },
    interpolation: {
      escapeValue: false
    },
    backend
  })

export function addTranslations(
  language: string,
  namespace: string,
  translations: Record<string, string>
) {
  i18n.addResourceBundle(language, namespace, translations, true, true)
}

export default i18nInstance
