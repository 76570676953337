/* eslint-disable no-console */
import { useSelector } from "react-redux"

import { selectSessionIds } from "@meili/services/state/config/configSlice"

import { encodeJSONtoBase64 } from "@meili/utils/dist/utils"

// Save data to a named object in the local storage
export const localStorageSaveState = (name, data, dataKey) => {
  try {
    localStorage?.setItem(name, JSON.stringify(data))
    localStorage?.setItem(`${name}-key`, encodeJSONtoBase64(dataKey || data))
  } catch (error) {
    console.error(error)
  }
}

// Load data from a named object in the local storage
export const localStorageLoadState = (name, dataKey) => {
  try {
    const storedState = JSON.parse(localStorage?.getItem(name))

    const isValid =
      encodeJSONtoBase64(dataKey || storedState) ===
      localStorage?.getItem(`${name}-key`)

    return storedState && isValid ? storedState : {}
  } catch (error) {
    console.error(error)
    return {}
  }
}

// Save data to a named object in the local storage
export const sessionStorageSaveState = (name, data, dataKey) => {
  try {
    sessionStorage?.setItem(name, JSON.stringify(data))
    sessionStorage?.setItem(`${name}-key`, encodeJSONtoBase64(dataKey || data))
  } catch (error) {
    console.error(error)
  }
}

// Load data from a named object in the local storage
export const sessionStorageLoadState = (name, dataKey) => {
  try {
    const storedState = JSON.parse(sessionStorage?.getItem(name))

    const isValid =
      encodeJSONtoBase64(dataKey || storedState) ===
      sessionStorage?.getItem(`${name}-key`)

    return storedState && isValid ? storedState : {}
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const getStorageHeaders = () => {
  const meiliSession = sessionStorage?.getItem("meili-session")
  const meiliCustomer = localStorage?.getItem("meili-customer")

  return {
    meiliSessionId: meiliSession ? JSON.parse(meiliSession)?.id : undefined,
    meiliCustomerId: meiliCustomer ? JSON.parse(meiliCustomer)?.id : undefined
  }
}

export function useStorageHeaders() {
  const meiliSession = sessionStorage?.getItem("meili-session")
  const meiliCustomer = localStorage?.getItem("meili-customer")

  const { meiliSessionId, meiliCustomerId } = useSelector(selectSessionIds)

  return {
    meiliSessionId: meiliSession
      ? JSON.parse(meiliSession)?.id
      : meiliSessionId,
    meiliCustomerId: meiliCustomer
      ? JSON.parse(meiliCustomer)?.id
      : meiliCustomerId
  }
}
