import { Buffer } from "buffer"

export const currencyFormatter = (locale, currency) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency
  })

/**
 * Encode JSON object into a base64 encoded string
 */
export const encodeJSONtoBase64 = (data) => {
  // Convert the JSON to a string and buffer it
  const dataBuff = Buffer.from(JSON.stringify(data), "utf8")
  // Return converted into base64
  return dataBuff.toString("base64")
}

/**
 * Decode base64 encoded string to JSON string
 */
export const decodeBase64toJSON = (data) =>
  JSON.parse(Buffer.from(data, "base64").toString())

export const handleError = (error, name = "Error") => {
  const e = new Error(error)
  e.name = name

  throw e
}

export class APIError extends Error {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}
