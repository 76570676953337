import { useEffect, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"

let subscribers = []

const resizeObserver = new ResizeObserver((entries) =>
  entries.forEach((entry) => {
    const match = subscribers.find(
      (subscriber) => subscriber.target === entry.target
    )

    if (match) {
      match.listener(entry)
    }
  })
)

function upsertListener(target, listener) {
  if (!target) {
    return
  }
  const match = subscribers.find((subscriber) => subscriber.target === target)
  if (match) {
    match.listener = listener
  } else {
    subscribers.push({ target, listener })
  }
}

function detach(target) {
  if (!target) {
    return
  }
  resizeObserver.unobserve(target)
  subscribers = subscribers.filter((subscriber) => subscriber.target !== target)
}

/**
 *
 * @param {(entry: ResizeObserverEntry) => void} listener function that
 * @returns { ref, setRef }
 */
export default function useResizeObserver(listener) {
  const [ref, setRef] = useState()

  useEffect(() => {
    upsertListener(ref, listener)
  }, [ref, listener])

  useEffect(() => {
    if (ref) {
      resizeObserver.observe(ref)
    }

    return () => {
      detach(ref)
    }
  }, [ref])

  return { ref, setRef }
}
