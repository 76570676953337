// Get the label of the list item.
export const getListItemLabel = (item) => (item ? item.label : "")
// Get the value of the list item.
export const getListItemValue = (item) => (item ? item.value : "")
// Get a list item by its value
export const getListItemByValue = (list, value) =>
  list.find((item) => item.value === `${value}`)
// Get a list item value by its label
export const getListItemValueByLabel = (list, label) =>
  list.find((item) => item.label === `${label}`)?.value || ""
// Get a list item label by its value
export const getListItemLabelByValue = (list, value) =>
  list.find((item) => item.value === `${value}`)?.label || ""
// Get all the labels from a list as an array
export const getListItemLabels = (list) => list.map((item) => item.label)

export const getListItemChildren = (list) =>
  getListItemLabels(list)?.map((item) => ({
    children: item,
    value: item
  }))

export const getListItemSelectedChild = (list, value) => ({
  children: getListItemLabelByValue(list, value),
  value
})
