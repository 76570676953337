import propTypes from "propTypes"

import { mliClassNames } from "style/utils"

import "./style/_chunk-error-message.scss"

function ChunkErrorMessage({ message }) {
  return (
    <div className={mliClassNames("chunk-error-message")}>
      <h1>{message}</h1>
    </div>
  )
}

ChunkErrorMessage.propTypes = {
  message: propTypes.string.isRequired
}

export default ChunkErrorMessage
