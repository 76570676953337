export function pick(obj, ...keys) {
  if (!obj) {
    return null
  }

  const result = {}
  const flatKeys = keys.flat()
  flatKeys?.forEach((key) => {
    if (obj[key]) {
      result[key] = obj[key]
    }
  })
  return result
}

export function omit(obj, ...keys) {
  if (!obj) {
    return null
  }

  const result = { ...obj }

  const flatKeys = keys.flat()
  flatKeys?.forEach((key) => {
    delete result[key]
  })

  return result
}

export function keyBy(arr, key) {
  const result = {}
  arr?.forEach((el) => {
    result[el[key]] = el
  })
  return result
}

export function sumBy(arr, fn) {
  let result = 0
  arr?.forEach((el) => {
    result += fn(el)
  })
  return result
}

export function uniq(arr) {
  return [...new Set(arr)]
}

export function union(arrA = [], arrB = []) {
  return uniq([...arrA, ...arrB])
}

export function intersection(...arrays) {
  let result = arrays[0] || []
  arrays.forEach((arr) => {
    result = result?.filter((x) => arr.includes(x))
  })
  return result
}

export function countBy(arr, key) {
  const result = {}
  arr?.forEach((el) => {
    if (key) {
      result[el[key]] = (result[el[key]] ?? 0) + 1
    } else {
      result[el] = (result[el] ?? 0) + 1
    }
  })
  return result
}

export function sortBy(arr, fn) {
  return arr?.sort((a, b) => (fn(a) < fn(b) ? -1 : 1))
}

export function uniqBy(arr, key) {
  const result = []
  const obj = {}
  arr?.forEach((el) => {
    if (obj[el[key]] === undefined) {
      obj[el[key]] = el
      result.push(el)
    }
  })
  return result
}

export function intersectionBy(...args) {
  const key = args.pop()

  let result = args[0] || []
  args.forEach((arr) => {
    result = result.filter((x) => arr.map((el) => el[key]).includes(x[key]))
  })

  return result
}

export function toString(arg) {
  if (typeof arg === "string") return arg
  if (arg == null) return ""
  return `${arg}`
}

export function capitalize(arg) {
  const str = toString(arg).toLowerCase()
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function isNil(value) {
  return value == null
}

export function noop() {
  // does nothing
}

export function times(number, func) {
  return [...Array(number)].map((_, index) => func(index))
}

export function isEmpty(arg) {
  return (
    [Object, Array].includes((arg || {})?.constructor) &&
    !Object.entries(arg || {}).length
  )
}

/**
 * Gets the value at path of object. If the resolved value is undefined,
 * the defaultValue is returned in its place.
 * [Source](https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get)
 * @param obj The object to query.
 * @param path The path of the property to get in dot notation e.g. `user.firstName`.
 * @param defaultValue The value returned for undefined resolved values.
 * @returns returns the resolved value.
 */
export function getValueAtObjectPath(obj, path, defaultValue = undefined) {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export const compact = <T>(
  array: (T | false | null | 0 | "" | undefined | number)[]
): T[] => {
  if (array === undefined) {
    throw new Error("Array argument is undefined")
  }

  if (!Array.isArray(array)) {
    throw new TypeError("Expected an array as input.")
  }

  return array.filter(Boolean) as T[]
}
