import { Buffer } from "buffer"
// Utility functions for deeplinks

// Split string on any char that isnt - or _
// Try and parse a locale from the first part
// Works for examples like en_US@rg=dezzzz
export const parseLocale = (locale: string) =>
  locale?.replace(/_/g, "-")?.split(/[^a-zA-Z0-9-]+/)[0]
/**
 * Convert base64 encoded JSON back into a usable JSON object.
 */
export const deeplinkBase64ToJSON = (data: string | null) => {
  if (data) {
    try {
      return JSON.parse(Buffer.from(data, "base64").toString())
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return { error }
    }
  }
  return {}
}

export const parseDeeplink = (search: string) => {
  const queryParams = new URLSearchParams(search)
  const params: { [key: string]: string } = {}

  Array.from(queryParams.entries()).forEach((queryParam) => {
    const [key, value] = queryParam

    if (key === "locale") {
      params[key] = parseLocale(value)
    } else {
      params[key] = value
    }
  })

  return params
}
