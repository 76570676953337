import { lazy } from "react"

import { CONFIG } from "@meili/config"

export function load(
  componentImport,
  attempts = CONFIG.REACT_APP_CHUNK_RETRIES
) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component)
      })
      .catch((error) => {
        setTimeout(() => {
          if (attempts < 1) {
            reject(error)
            return
          }

          load(componentImport, attempts - 1).then(resolve, reject)
        }, 1000)
      })
  })
}

function lazyLoad(componentImport) {
  return lazy(() => load(componentImport))
}

export default lazyLoad
