import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { CONFIG } from "@meili/config"
import { sortBy } from "@meili/utils/dist/helper-methods"

import { doConfigAPI } from "../../api/configAPI"
import { statusEnums, getErrorDetails } from "../../api/utils"

// Initial state
export const initialState = {
  status: statusEnums.INITIAL,
  ptid: CONFIG.REACT_APP_VEHICLE_API_PTID,
  meiliSessionId: null,
  meiliCustomerId: null,
  countryCode: null,
  query: null,
  sameSession: false,
  membershipRequired: false,
  // This is for native. Web uses config.json or MeiliParams as source of truth
  currencyCode: "EUR",
  locale: "en-IE",
  timeStamp: null,
  countries: [],
  currencies: [],
  supplierLoyaltyPrograms: [],
  partnerLoyaltyPrograms: []
}

// Actions
export const getConfig = createAsyncThunk(
  "config/getConfig",
  async (config, { rejectWithValue }) => {
    // Try to get the config
    try {
      const { payload } = await doConfigAPI(config)

      // Return the config
      return {
        ...payload,
        meiliCustomerId: config.meiliCustomerId
      }
    } catch (error) {
      throw rejectWithValue(getErrorDetails(error, "errorAPIConfigFailed"))
    }
  }
)

// Reducer
export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateCurrencyCode(state, action) {
      state.currencyCode = action.payload
    },
    updatePTID(state, action) {
      state.ptid = action.payload
    },
    setQuery(state, action) {
      state.query = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state, action) => {
        state.status = statusEnums.PENDING
        state.meiliCustomerId = action.meta?.arg?.meiliCustomerId
      })
      .addCase(getConfig.rejected, (state) => {
        state.status = statusEnums.REJECTED
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.sameSession =
          state.meiliSessionId === action.payload.meiliSessionId

        state.status = statusEnums.FULFILLED
        // Get important config info
        state.membershipRequired = action.payload.mandatoryMembershipNumber
        state.meiliSessionId = action.payload.meiliSessionId
        state.countryCode = action.payload.countryCode
        state.currencies = sortBy(
          action.payload.currencies,
          (currency) => currency?.name
        )
        state.countries = action.payload.countries
        state.supplierLoyaltyPrograms =
          action.payload.supplierLoyaltyPrograms ?? []
        state.partnerLoyaltyPrograms =
          action.payload.partnerLoyaltyPrograms ?? []
      })
  }
})

// Selectors
export const selectConfig = (state) => state?.config
// Get currency code
export const selectCurrencyCode = (state) => selectConfig(state)?.currencyCode
// Get country code
export const selectCountryCode = (state) => selectConfig(state)?.countryCode
// Get locale code
export const selectLocaleValue = (state) => selectConfig(state)?.locale
// Get loyalty programs
export const selectLoyaltyPrograms = (state) => ({
  supplierLoyaltyPrograms: selectConfig(state)?.supplierLoyaltyPrograms,
  partnerLoyaltyPrograms: selectConfig(state)?.partnerLoyaltyPrograms
})
export const selectSessionIds = (state) => {
  const config = selectConfig(state)

  return {
    meiliSessionId: config.meiliSessionId,
    meiliCustomerId: config.meiliCustomerId
  }
}

export const selectQuery = (state) => selectConfig(state)?.query
export const selectSameSessionCheck = (state) =>
  selectConfig(state)?.sameSession

/**
 * Get currencies configured for this partner
 */
export const selectCurrencies = (state) =>
  selectConfig(state)?.currencies || initialState.currencies

export const isPartnerLoyaltyRequired = (state) =>
  selectConfig(state).membershipRequired

export const { updateCurrencyCode, updateIds, updatePTID, setQuery } =
  configSlice.actions

export default configSlice.reducer
