import { useCallback, useState } from "react"
import useResizeObserver from "observer/useResizeObserver"

import { responsiveWidthSizes, responsiveHeightSizes } from "./enums"

export function isInWidthRange(width, range, offset) {
  return range.maxWidth
    ? width >= range.minWidth && width <= range.maxWidth
    : width >= range.minWidth + offset
}

export function isInHeightRange(height, range, offset) {
  return range.maxHeight
    ? height >= range.minHeight && height <= range.maxHeight
    : height >= range.minHeight + offset
}

/**
 * Get element size and check if it's SMALL, MEDIUM or LARGE
 * based on enum values
 * @param {ResizeObserverEntry} entry
 * @returns {string} SMALL, MEDIUM or LARGE
 */
export function getElementSizeType(entry, offset = 0) {
  const { width, height } = entry.contentRect

  if (width === 0) {
    return { width: "widget-loading" }
  }

  const sizeWidthType = Object.keys(responsiveWidthSizes).find((type) =>
    isInWidthRange(width, responsiveWidthSizes[type], offset)
  )

  const sizeHeightType = Object.keys(responsiveHeightSizes).find((type) =>
    isInHeightRange(height, responsiveHeightSizes[type], offset)
  )

  return {
    width: sizeWidthType,
    height: sizeHeightType
  }
}

/**
 * @typedef {Object} Size
 * @property {string} sizeWidthType
 * @property {string} sizeHeightType
 * @property {function(): void} setRef
 */
/**
 *
 * @returns {Size} with 2 properties: setRef, sizeWidthType, sizeHeightType
 */
export default function useElementSizeType(offset) {
  const [sizeWidthType, setSizeWidthType] = useState()
  const [sizeHeightType, setSizeHeightType] = useState()

  const handleResize = useCallback(
    (entry) => {
      const size = getElementSizeType(entry, offset)

      setSizeWidthType(size.width)
      setSizeHeightType(size.height)
    },
    [offset]
  )
  const { setRef } = useResizeObserver(handleResize)
  return { sizeWidthType, sizeHeightType, setRef }
}
