const root = (() => {
  let element = document.getElementById("mli-root")

  if (!element) {
    element = document.createElement("div")
    element.setAttribute("id", "mli-root")

    document.body.appendChild(element)
  }

  return element
})()

export default root

/**
 * We can use this to set locale in root div and it will automatically update library
 */
export function setLocale(locale: string) {
  root.setAttribute("data-locale", locale)
}

export function setCurrency(currency: string) {
  root.setAttribute("data-currency", currency)
}
