import { useState, useContext, createContext, ReactNode, useMemo } from "react"

interface AriaLiveContextProps {
  ariaLiveContent: string
  setAriaLiveContent: (newContent: string) => void
}

const AriaLiveContext = createContext<AriaLiveContextProps | null>(null)

// Custom hook to manage the ARIA live region content
function useAriaLiveContext(): AriaLiveContextProps {
  const context = useContext(AriaLiveContext)

  if (!context) {
    throw new Error(
      "useAriaLiveContext must be used within an AriaLiveProvider."
    )
  }

  return context
}

interface AriaLiveProviderProps {
  readonly children: ReactNode
}

function AriaLiveProvider({ children }: AriaLiveProviderProps) {
  const [ariaLiveContent, setAriaLiveContent] = useState("")

  const memoizedValue = useMemo(
    () => ({ ariaLiveContent, setAriaLiveContent }),
    [ariaLiveContent, setAriaLiveContent]
  )

  return (
    <AriaLiveContext.Provider value={memoizedValue}>
      {children}
    </AriaLiveContext.Provider>
  )
}

export { AriaLiveProvider, useAriaLiveContext }
