import propTypes from "propTypes"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Button from "ui/Button"

import { mliClassNames } from "style/utils"

import ChunkErrorMessage from "./ChunkErrorMessage"

function ErrorMessage(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { error } = props

  if (error?.name === "ChunkLoadError") {
    return <ChunkErrorMessage message={t("chunkErrorBoundaryMessage")} />
  }

  return (
    <div className={mliClassNames("error-message")}>
      <h1>{t("errorBoundaryMessage")}</h1>
      <Button
        id="mliErrorMessage"
        className={mliClassNames("error-message-button")}
        onClick={() => {
          navigate("/")
        }}
      >
        {t("errorBoundaryMessageLink")}
      </Button>
    </div>
  )
}

ErrorMessage.propTypes = {
  error: propTypes.shape({
    message: propTypes.string,
    name: propTypes.string,
    type: propTypes.string
  })
}

ErrorMessage.defaultProps = {
  error: undefined
}

export default ErrorMessage
