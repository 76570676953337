import {
  decodeBase64toJSON,
  encodeJSONtoBase64,
  APIError
} from "@meili/utils/dist/utils"
import localeList from "@meili/lists/dist/json/localeList.json"
import { CONFIG } from "@meili/config"

// Enums for use with the API statuses
export const statusEnums = {
  INITIAL: "initial",
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  BOOKING: "booking"
}

export const isInitial = (status) => status === statusEnums.INITIAL
export const isPending = (status) => status === statusEnums.PENDING
export const isFulfilled = (status) => status === statusEnums.FULFILLED
export const isRejected = (status) => status === statusEnums.REJECTED
export const isBooking = (status) => status === statusEnums.BOOKING

// Make a separate date and time value into one dateTime value
// for consumption by the API.
export const makeDateTimeForAPI = (date, time) => `${date} ${time}`

export const setupFeatureFlaggedHeaders = (loyalty) => {
  if (loyalty) {
    // TODO: once we have a connection to a loyalty burn system this
    // will require updates.
    return {
      authToken: "43567", // change this to the number points you need
      userId: "username123" // no need to change this
    }
  }
  return {}
}

export const getHeadersForAPI = (config) => {
  const {
    ptid,
    locale,
    meiliSessionId,
    meiliCustomerId,
    debug,
    loyalty,
    experimentVariantId
  } = config

  // Get any dynamic headers
  const featFlagHeaders = setupFeatureFlaggedHeaders(loyalty)

  const language =
    localeList.find((localeListItem) => localeListItem.value === locale)
      ?.language || locale

  return {
    ptid,
    "Accept-Language": language,
    "app-version": CONFIG.REACT_APP_VERSION,
    "app-timestamp": CONFIG.REACT_APP_BUILD_DATE,
    "Content-Type": "application/json",
    ...(meiliSessionId && { meiliSessionId }),
    ...(meiliCustomerId && { meiliCustomerId }),
    ...(debug && { traceMessageLogging: true }),
    ...(experimentVariantId && { experimentVariantId }),
    ...featFlagHeaders
  }
}

// Get the extras references needed to book
export const getExtraReferences = (selectedExtras) => {
  return Object.keys(selectedExtras)?.map((extra) => {
    const quantity = selectedExtras[extra]?.quantity
    return { quantity, reference: extra }
  })
}

// Create a booking reference which contains the extras.
export const mergeReferenceWithOptionalExtras = (
  selectedBundleReference,
  selectedExtras
) => {
  // If there are extras, we need to add them to the reference for booking.
  if (Object.keys(selectedExtras).length != 0) {
    // Create a new bundle reference with extras
    const newBundle = {
      ...(selectedBundleReference
        ? decodeBase64toJSON(selectedBundleReference)
        : {}),
      extraReferences: selectedExtras
    }
    // Return the new reference, base64 encoded.
    return encodeJSONtoBase64(newBundle)
  }
  // If no extras, just return the bundle reference as it was.
  return selectedBundleReference
}

export const getErrorDetails = (error, userMessage) => ({
  name: error?.name,
  stack: error?.stack,
  message: error?.message,
  errors: error?.response?.errors,
  traceId: error.response?.errors?.[0]?.traceId,
  userMessage
})

// check fetch response and throw errors
export const checkResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json()
    throw new APIError(`Request failed with status ${response.status}`, error)
  }

  const payload = await response.json()
  return {
    payload,
    headers: {
      abTestId: response.headers.get("abtestvariants")
    }
  }
}
